import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
import openChatBot from "../functions/openChatBot";
const DigitalTransformation = () => {
  const intl = useIntl();
  // const data = useStaticQuery(graphql`
  //   query {
  //     digitalTransformSlide: file(relativePath: { eq: "bodybg.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1500) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     Ceo: file(relativePath: { eq: "CEO.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 750) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //   }

  // `);


  const data = useStaticQuery(graphql`
    query {
      digitalTransformSlide: file(relativePath: { eq: "bodybg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Ceo: file(relativePath: { eq: "CEO.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
    }
  `);
  return (

    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "static-s31" })} />

        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.digitalTransformSlide.childImageSharp.fluid}
                className="img-responsive"
              />
              <div className="mainlyh">
                <div className="mainlyl">{intl.formatMessage({ id: "innerpage-1" })}</div>
                <div className="mainlyr">
                  <Img fluid={data.Ceo.childImageSharp.fluid}
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </FadeIn>
          <div>


            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <h5>{intl.formatMessage({ id: "innerpage-2" })}</h5>
                <p>{intl.formatMessage({ id: "innerpage-3" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-4" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-5" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-6" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-7" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-8" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-9" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-10" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-11" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-12" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-13" })}</p>

                <p>{intl.formatMessage({ id: "innerpage-14" })}</p>



                <span>{intl.formatMessage({ id: "innerpage-15" })}</span>

                <span>{intl.formatMessage({ id: "innerpage-16" })}</span>

                <span>{intl.formatMessage({ id: "innerpage-17" })}</span>

              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default DigitalTransformation;
